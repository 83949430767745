import React, { useEffect, useState } from 'react';
import Footer from './Footer';
import * as styles from './Layout.module.scss';
import { SHOULD_RUN_GOOGLE_OPTIMIZE } from '../../constants';

interface LayoutProps {
  children: React.ReactNode;
}

const Layout = ({ children }: LayoutProps): React.ReactElement => {
  const [isHidden, setIsHidden] = useState(SHOULD_RUN_GOOGLE_OPTIMIZE);
  // Initialize google optimize experiment on 'optimize.activate'
  useEffect(() => {
    if (SHOULD_RUN_GOOGLE_OPTIMIZE) {
      window.dataLayer = window.dataLayer || [];
      window.dataLayer.push({ event: 'optimize.activate' });
      setIsHidden(false);
    }
  }, []);

  return (
    <div style={isHidden ? { opacity: 0 } : undefined} className={styles.page}>
      <div className={styles.main}>{children}</div>
      <Footer></Footer>
    </div>
  );
};

export default Layout;
