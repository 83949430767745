import { GatsbyImage, IGatsbyImageData } from 'gatsby-plugin-image';
import React from 'react';
import { replaceNewLinesWithBr } from '../../utils/sanity';
import { clsx } from '../../utils/utils';

import * as styles from './ServiceBox.module.scss';

export interface ServiceBoxProps {
  image: {
    asset: {
      url: string;
      gatsbyImageData: IGatsbyImageData;
    };
  };
  mobileImage?: {
    asset: {
      url: string;
      gatsbyImageData: IGatsbyImageData;
    };
  };
  title: string;
  text: string;
  useTallerBox?: boolean;
  className?: string;
}

const ServiceBox = ({
  image,
  mobileImage,
  title,
  text,
  useTallerBox,
  className,
}: ServiceBoxProps): React.ReactElement => {
  return (
    <div className={clsx(className, styles.container, useTallerBox && styles.tallerBox)}>
      <GatsbyImage
        image={image.asset.gatsbyImageData}
        alt=""
        className={clsx(styles.imageContainer, !mobileImage?.asset && styles.noMobileImage)}
        objectFit="cover"
      />
      {mobileImage && (
        <GatsbyImage
          image={mobileImage.asset.gatsbyImageData}
          alt=""
          className={styles.mobileImageContainer}
          objectFit="cover"
        />
      )}
      <div className={styles.textContainer}>
        <h3 className={styles.title}>{title}</h3>
        <p className={styles.text}>{replaceNewLinesWithBr(text)}</p>
      </div>
    </div>
  );
};

export default ServiceBox;
