import { graphql } from 'gatsby';
import React from 'react';
import { replaceNewLinesWithBr } from '../../utils/sanity';
import Form from '../ui/Form';
import chatElement from '../../images/formElement.svg';

import * as styles from './ChatSection.module.scss';

export const ChatSectionFragment = graphql`
  fragment ChatSection on SanityChatSection {
    title
    text
    email
  }
`;

export interface ChatSectionProps {
  title: string;
  text: string;
  email: string;
}

const ChatSection = ({ title, text, email }: ChatSectionProps): React.ReactElement => {
  return (
    <div className={styles.chatSection}>
      <div className={styles.chatSectionContainer}>
        <div className={styles.chatBoxContainer}>
          <div className={styles.chatBoxContent}>
            <div className={styles.textContainer}>
              <h2 className={styles.title}>{title}</h2>
              <p className={styles.text}>{replaceNewLinesWithBr(text)}</p>
            </div>
            <div className={styles.contactsContainer}>
              <a href={`mailto:${email}`} className={styles.contact}>
                {email}
              </a>
            </div>
          </div>
        </div>
        <div className={styles.formContainer}>
          <div className={styles.formContent}>
            <span className={styles.fakeAnchor} id="form-anchor"></span>
            <h3 className={styles.formTitle}>Why wait? Get started now.</h3>
            <p className={styles.formText}>
              Fill out our contact form below and a member of our team will get back to you
              promptly.
            </p>
            <Form />
          </div>
        </div>
        <div className={styles.elementContainer}>
          <img src={chatElement} alt="" />
        </div>
      </div>
    </div>
  );
};

export default ChatSection;
