// extracted by mini-css-extract-plugin
export var chatBoxContainer = "ChatSection-module--chatBoxContainer--Lpcfi";
export var chatBoxContent = "ChatSection-module--chatBoxContent---QRFn";
export var chatSection = "ChatSection-module--chatSection--izRDR";
export var chatSectionContainer = "ChatSection-module--chatSectionContainer--NR8oJ";
export var contact = "ChatSection-module--contact--W3gTs";
export var contactsContainer = "ChatSection-module--contactsContainer--yOdjo";
export var elementContainer = "ChatSection-module--elementContainer--6krlN";
export var fakeAnchor = "ChatSection-module--fakeAnchor--Q7ihC";
export var formContainer = "ChatSection-module--formContainer--xK62U";
export var formContent = "ChatSection-module--formContent--Zu10N";
export var formText = "ChatSection-module--formText--Ke3+k";
export var text = "ChatSection-module--text--2ueKp";