import React from 'react';
import logo from '../../images/logo.png';

import * as styles from './Footer.module.scss';

const Footer = (): React.ReactElement => {
  return (
    <footer className={styles.footer}>
      <div className={styles.footerContainer}>
        <div className={styles.logoContainer}>
          <img src={logo} alt="" />
        </div>
        <div className={styles.copyrightContainer}>
          <span className={styles.span}>Copyright {new Date().getFullYear()} </span>
          <div className={styles.divider}> &nbsp; | &nbsp;</div>
          <span className={styles.span}>All rights reserved to Rock Paper Studio</span>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
