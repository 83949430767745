import { graphql } from 'gatsby';
import { GatsbyImage, IGatsbyImageData } from 'gatsby-plugin-image';
import React from 'react';
import { replaceNewLinesWithBr } from '../../utils/sanity';
import { clsx, replaceSquareBracketsWithEm } from '../../utils/utils';
import ServiceBox from '../ui/ServiceBox';

import * as styles from './ServicesSection.module.scss';

export const ServicesSectionFragment = graphql`
  fragment ServicesSection on SanityServicesSection {
    title
    services {
      title
      text
      image {
        asset {
          url
          gatsbyImageData(width: 1080, placeholder: BLURRED, formats: [AUTO, WEBP, AVIF])
        }
      }
      mobileImage {
        asset {
          url
          gatsbyImageData(width: 360, placeholder: BLURRED, formats: [AUTO, WEBP, AVIF])
        }
      }
      useTallerBox
    }
  }
`;

interface Service {
  title: string;
  text: string;
  image: {
    asset: {
      url: string;
      gatsbyImageData: IGatsbyImageData;
    };
  };
  mobileImage: {
    asset: {
      url: string;
      gatsbyImageData: IGatsbyImageData;
    };
  };
  useTallerBox?: boolean;
}

export interface ServicesSectionProps {
  title: string;
  services: Array<Service>;
}

function getServicesColumnHeight(servicesColumn: Array<Service>): number {
  return servicesColumn.reduce(
    (accHeight, service) => accHeight + (service.useTallerBox ? 2 : 1),
    0,
  );
}

const ServicesSection = ({ title, services }: ServicesSectionProps): React.ReactElement => {
  const leftColumnServices: Array<Service> = [];
  const rightColumnServices: Array<Service> = [];

  for (const service of services) {
    if (
      getServicesColumnHeight(rightColumnServices) < getServicesColumnHeight(leftColumnServices)
    ) {
      rightColumnServices.push(service);
    } else {
      leftColumnServices.push(service);
    }
  }

  return (
    <div className={styles.servicesSection}>
      <div className={styles.servicesSectionContainer}>
        <h2 className={styles.title}>{replaceSquareBracketsWithEm(title)}</h2>
        <div className={styles.desktopServicesColumnsWrapper}>
          <div className={styles.servicesColumnContainer}>
            {leftColumnServices.map((service, i) => (
              <ServiceBox
                key={i}
                image={service.image}
                text={service.text}
                title={service.title}
                mobileImage={service.mobileImage}
                useTallerBox={service.useTallerBox}
              />
            ))}
          </div>
          <div className={styles.servicesColumnContainer}>
            {rightColumnServices.map((service, i) => (
              <ServiceBox
                key={i}
                image={service.image}
                text={service.text}
                title={service.title}
                mobileImage={service.mobileImage}
                useTallerBox={service.useTallerBox}
              />
            ))}
          </div>
        </div>
        <div className={styles.mobileServicesColumn}>
          <div className={styles.servicesColumnContainer}>
            {services.map((service, i) => (
              <ServiceBox
                key={i}
                image={service.image}
                text={service.text}
                title={service.title}
                mobileImage={service.mobileImage}
                useTallerBox={service.useTallerBox}
              />
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ServicesSection;
