import React from 'react';

const GenericLinkSerializer = (props: {
  mark: {
    url: string;
  };
  children: React.ReactNode;
}): React.ReactElement => {
  return <a href={props.mark.url}>{props.children}</a>;
};

const serializers = {
  marks: {
    genericLink: GenericLinkSerializer,
  },
};

export default serializers;
