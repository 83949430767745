import { navigate } from 'gatsby';
import * as Sentry from '@sentry/react';
import { wait, withDataLayer } from './utils/utils';
import { Field } from './utils/forms';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function encodeFormData(data: any) {
  return Object.keys(data)
    .map(key => encodeURIComponent(key) + '=' + encodeURIComponent(data[key]))
    .join('&');
}

export async function sendNetlifyEmail(
  fieldsByName: Record<'name' | 'phoneNumber' | 'email' | 'company' | 'message', Field>,
) {
  if (process.env.NODE_ENV === 'development') {
    // Code to test form states (should be commented)
    await wait(2000); // Case loading
    // throw new Error('Got response with status code 400'); // Case unknown error
    // throw new Error('Failed to fetch'); // Case network error
    // return true; // Case success
  }

  const resp = await fetch('/', {
    method: 'POST',
    headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
    body: encodeFormData({
      'form-name': 'contact',
      ...{
        name: fieldsByName.name.value,
        email: fieldsByName.email.value,
        company: fieldsByName.company.value,
        phoneNumber: fieldsByName.phoneNumber.value,
        message: fieldsByName.message.value,
      },
    }),
  });
  if (resp.status !== 200) {
    throw new Error('Got response with status code ' + resp.status);
  }
  withDataLayer(dataLayer => {
    dataLayer.push({ event: 'form-submit' });
  });
  return true;
}
