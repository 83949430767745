import { graphql } from 'gatsby';
import React from 'react';
import { replaceNewLinesWithBr } from '../../utils/sanity';
import { replaceSquareBracketsWithEm } from '../../utils/utils';
import closeHand from '../../images/closeHand.png';
import openHand from '../../images/openHand.png';

import * as styles from './IntroSection.module.scss';

export const IntroSectionFragment = graphql`
  fragment IntroSection on SanityIntroSection {
    title
    text
  }
`;

export interface IntroSectionProps {
  title: string;
  text: string;
}

const IntroSection = ({ title, text }: IntroSectionProps): React.ReactElement => {
  return (
    <div className={styles.introSection}>
      <div className={styles.closeHandElementContainer}>
        <img src={closeHand} alt="" />
      </div>
      <div className={styles.openHandElementContainer}>
        <img src={openHand} alt="" />
      </div>
      <div className={styles.introSectionContainer}>
        <h2 className={styles.title}>{replaceSquareBracketsWithEm(title)}</h2>
        <p className={styles.text}>{replaceNewLinesWithBr(text)}</p>
      </div>
    </div>
  );
};

export default IntroSection;
