import { graphql } from 'gatsby';
import React from 'react';
import { replaceSquareBracketsWithEm } from '../../utils/utils';
import BlockContent from '@sanity/block-content-to-react';
import serializers from '../../serializers';
import { StaticImage } from 'gatsby-plugin-image';

import * as styles from './CloseSection.module.scss';

export const CloseSectionFragment = graphql`
  fragment CloseSection on SanityCloseSection {
    title
    _rawText(resolveReferences: { maxDepth: 4 })
  }
`;
export interface CloseSectionProps {
  title: string;
  _rawText: string;
}

const CloseSection = ({ title, _rawText }: CloseSectionProps): React.ReactElement => {
  return (
    <div className={styles.closeSection}>
      <div className={styles.closeSectionContainer}>
        <StaticImage className={styles.image} src="../../images/closeSectionImage.png" alt="" />
        <div className={styles.textContainer}>
          <h2 className={styles.title}>{replaceSquareBracketsWithEm(title)}</h2>
          <BlockContent
            className={styles.text}
            blocks={_rawText}
            renderContainerOnSingleChild
            serializers={serializers}
          />
        </div>
      </div>
    </div>
  );
};

export default CloseSection;
