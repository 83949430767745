// extracted by mini-css-extract-plugin
export var buttonContainer = "Hero-module--buttonContainer--lFuGS";
export var buttonText = "Hero-module--buttonText--E-XkG";
export var carouselImage = "Hero-module--carouselImage--Yv0Xr";
export var desktopImageContainer = "Hero-module--desktopImageContainer--llJ9E";
export var elementContainer = "Hero-module--elementContainer--b-Nlx";
export var hero = "Hero-module--hero--bnM3l";
export var heroContainer = "Hero-module--heroContainer--aE7ym";
export var logo = "Hero-module--logo--c0tFa";
export var logoContainer = "Hero-module--logoContainer--bjsL+";
export var mobileCarousel = "Hero-module--mobileCarousel--T+Ky1";
export var mobileCarouselContainer = "Hero-module--mobileCarouselContainer--tc-h-";
export var scroll = "Hero-module--scroll--aKC7u";
export var text = "Hero-module--text--B1LiG";
export var textContainer = "Hero-module--textContainer--1Zvcl";
export var title = "Hero-module--title--BE-js";