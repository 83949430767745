import React from 'react';
import { GatsbyImage, IGatsbyImageData } from 'gatsby-plugin-image';
import logo from '../../images/logo.png';
import { replaceNewLinesWithBr } from '../../utils/sanity';
import { graphql } from 'gatsby';
import AnchorLink from 'react-anchor-link-smooth-scroll';
import heroElement from '../../images/heroElement.svg';

import * as styles from './Hero.module.scss';

export const HeroFragment = graphql`
  fragment Hero on SanityHero {
    title
    text
    desktopImage {
      asset {
        url
        gatsbyImageData(width: 980, placeholder: BLURRED, formats: [AUTO, WEBP, AVIF])
      }
    }
    mobileImages {
      asset {
        url
        gatsbyImageData(width: 185, placeholder: BLURRED, formats: [AUTO, WEBP, AVIF])
      }
    }
  }
`;

export interface HeroProps {
  title: string;
  text: string;
  desktopImage: {
    asset: {
      url: string;
      gatsbyImageData: IGatsbyImageData;
    };
  };
  mobileImages: Array<{
    asset: {
      url: string;
      gatsbyImageData: IGatsbyImageData;
    };
  }>;
}

const Hero = ({ title, text, desktopImage, mobileImages }: HeroProps): React.ReactElement => {
  const carouselImages = [...mobileImages, ...mobileImages];

  return (
    <div className={styles.hero}>
      <div className={styles.heroContainer}>
        <div className={styles.logoSection}>
          <img src={logo} alt="" className={styles.logo} />
        </div>
        <div className={styles.textContainer}>
          <h1 className={styles.title}>{title}</h1>
          <p className={styles.text}>{replaceNewLinesWithBr(text)}</p>
          <AnchorLink href="#form-anchor" className={styles.buttonContainer}>
            <span className={styles.buttonText}>Get started</span>
          </AnchorLink>
        </div>
      </div>
      <div className={styles.desktopImageContainer}>
        <GatsbyImage
          alt=""
          image={desktopImage.asset.gatsbyImageData}
          className={styles.desktopImage}
        />
        <div className={styles.elementContainer}>
          <img src={heroElement} alt="" />
        </div>
      </div>
      <div className={styles.mobileCarouselContainer}>
        <div className={styles.mobileCarousel}>
          {carouselImages.map((mobileImage, i) => (
            <div className={styles.carouselImage} key={i}>
              <img src={mobileImage.asset.url} alt="" />
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Hero;
