import React from 'react';
import Cookies from 'js-cookie';

import { DataLayer } from '../types/globals';

export function withDataLayer(func: (dataLayer: DataLayer) => void): void {
  if (typeof window !== 'undefined') {
    window.dataLayer = window.dataLayer || [];
    func(window.dataLayer);
  }
}

/**
 * Takes any number of arguments, filters "false" ones (false, null, undefined) and joins the rest with spaces.
 * Use this to easily add multiple classes together, where some of them should be added conditionally.
 *
 * Example: clsx('someClass', condition && otherClass, potentiallyUndefinedClass)
 *
 * Function inspired by https://github.com/lukeed/clsx
 */
export const clsx = (...args: Array<string | false | null | undefined>): string =>
  args.filter(Boolean).join(' ');

export function setCookie(
  name: string,
  value: string,
  {
    expires = 365,
    sameSite = 'none',
    ...extraOptions
  }: {
    expires?: number;
    sameSite?: 'strict' | 'lax' | 'none';
  },
): void {
  const secure = location ? location.protocol === 'https:' : true;

  const cookieOptions = { expires, sameSite, secure, ...extraOptions };

  // Fallback for older browsers where can not set SameSite=None, SEE: https://web.dev/samesite-cookie-recipes/#handling-incompatible-clients
  if (sameSite === 'none') {
    Cookies.set(name + '-legacy', value, cookieOptions);
  }

  // set the regular cookie
  Cookies.set(name, value, cookieOptions);
}

export function getCookieValue(name: string): string | undefined {
  let cookieValue = Cookies.get(name);

  // if the cookieValue is undefined check for the legacy cookie
  if (cookieValue === undefined) {
    cookieValue = Cookies.get(name + '-legacy');
  }
  return cookieValue;
}

export function isDeviceMobile(): boolean {
  if (typeof window === 'undefined') {
    return false;
  }

  const mobileRegex = /Mobile|mini|Fennec|Android|iP(ad|od|hone)/;
  return (
    mobileRegex.test(window.navigator.appVersion) || mobileRegex.test(window.navigator.userAgent)
  );
}

export function slugify(str: string): string {
  return str
    .replace(/\s/gi, '-')
    .toLowerCase()
    .normalize('NFD')
    .replace(/[\u0300-\u036f]/g, '');
}

/**
 * Truncates a string depending on the maxLength and replace excess text with '...'
 */
export function truncateText(str: string, maxLength: number): string {
  if (str.length <= maxLength) {
    return str;
  }
  const strParts = str.match(/(^|[^A-zÀ-ú\-])+[A-zÀ-ú\-]+/g) || [];
  let truncateStr = '';
  for (const strPart of strParts) {
    if (truncateStr.length + strPart.length + 3 > maxLength) {
      break;
    }
    truncateStr += strPart;
  }
  return truncateStr + '...';
}

export function wait(ms: number) {
  return new Promise(function (resolve, reject) {
    setTimeout(resolve, ms);
  });
}

export function replaceSquareBracketsWithEm(str: string): Array<React.ReactElement | string> {
  const strParts = str.split(/[\[\]]/);
  return strParts.map((string, i) => (i % 2 !== 0 ? <em key={i}>{string}</em> : string));
}
