import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';

import Layout from '../../components/ui/Layout';

import { LocalizedSEO } from '../../fragments';
import '../../fragments.ts';
import { withI18n } from '../../utils/hocs';
import PageSEO from '../../components/PageSEO';
import Hero, { HeroProps } from '../../components/ui/Hero';
import IntroSection, { IntroSectionProps } from '../../components/sections/IntroSection';
import ServicesSection, { ServicesSectionProps } from '../../components/sections/ServicesSection';
import '../../styles/main.scss';
import CloseSection, { CloseSectionProps } from '../../components/sections/CloseSection';
import ChatSection, { ChatSectionProps } from '../../components/sections/ChatSection';

interface QueryData {
  sanityHomepage: {
    hero: HeroProps;
    introSection: IntroSectionProps;
    servicesSection: ServicesSectionProps;
    closeSection: CloseSectionProps;
    chatSection: ChatSectionProps;
    seo: LocalizedSEO;
  };
}

const IndexPage = (): React.ReactElement => {
  const data: QueryData = useStaticQuery(graphql`
    {
      sanityHomepage {
        hero {
          ...Hero
        }
        introSection {
          ...IntroSection
        }
        servicesSection {
          ...ServicesSection
        }
        closeSection {
          ...CloseSection
        }
        chatSection {
          ...ChatSection
        }
        seo {
          ...SEOWebsite
        }
      }
    }
  `);

  const { hero, introSection, servicesSection, closeSection, chatSection, seo } =
    data.sanityHomepage;

  return (
    <Layout>
      <PageSEO />
      <Hero {...hero} />
      <IntroSection {...introSection} />
      <ServicesSection {...servicesSection} />
      <CloseSection {...closeSection} />
      <ChatSection {...chatSection} />
    </Layout>
  );
};

export default withI18n(IndexPage);
