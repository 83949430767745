import React from 'react';
import { FaSpinner, FaCheck } from 'react-icons/fa';
import { useFormField, useForm } from '../../utils/forms';
import InputField from './InputField';
import { clsx } from '../../utils/utils';

import * as styles from './Form.module.scss';
import { sendNetlifyEmail } from '../../api';

const Form = (): React.ReactElement => {
  const fieldsByName = {
    name: useFormField<string>('', ['required']),
    email: useFormField<string>('', ['required', 'email']),
    company: useFormField<string>('', ['required']),
    phoneNumber: useFormField<string>('', ['required']),
    message: useFormField<string>('', ['required']),
  } as const;

  const { getFieldProps, renderSubmitButton, renderFormMessage, submitState } = useForm({
    fieldsByName,
    onSubmit,
    translateFunction: key => {
      return {
        'form.required_field_error': 'This field is required',
        'form.invalid_email_error': 'Invalid email (e.g. email@example.com)',
        'form.network_error': 'Network failed to send your request.',
        'form.unknown_error': 'An unexpected error occured. Please try again later.',
        'form.success_message': 'Thank you for your interest!',
      }[key];
    },
  });

  async function onSubmit() {
    return sendNetlifyEmail(fieldsByName);
  }

  return (
    <div className={styles.formContainer}>
      <form name="contact" data-netlify="true" data-netlify-honeypot="hidden-field">
        <label className={styles.hiddenField} htmlFor="language">
          Language
        </label>
        <input
          className={styles.hiddenField}
          name="hidden-field"
          placeholder="language"
          id="language"
          autoComplete="language"
          type="text"
        />
        <div className={styles.inputContainer}>
          <div className={styles.nameAndEmail}>
            <div className={styles.labelAndInputContainer}>
              <label className={styles.label} htmlFor="name-input">
                Name
              </label>
              <InputField
                className={styles.input + ' ' + styles.nameInput}
                type="text"
                id="name-input"
                name="name"
                helperTextClass={styles.helperText}
                {...getFieldProps(fieldsByName.name)}
              />
            </div>
            <div className={styles.labelAndInputContainer}>
              <label className={styles.label} htmlFor="email-input">
                Email
              </label>
              <InputField
                className={styles.input}
                type="email"
                id="email-input"
                name="email"
                helperTextClass={styles.helperText}
                {...getFieldProps(fieldsByName.email)}
              />
            </div>
          </div>

          <div className={styles.companyAndPhone}>
            <div className={styles.labelAndInputContainer}>
              <label className={styles.label} htmlFor="company-input">
                Company
              </label>
              <InputField
                className={styles.input}
                type="company"
                id="company-input"
                name="company"
                helperTextClass={styles.helperText}
                {...getFieldProps(fieldsByName.company)}
              />
            </div>
            <div className={styles.labelAndInputContainer}>
              <label className={styles.label} htmlFor="phoneNumber-input">
                Phone Number
              </label>
              <InputField
                className={styles.input}
                type="phoneNumber"
                id="phoneNumber-input"
                name="phoneNumber"
                helperTextClass={styles.helperText}
                {...getFieldProps(fieldsByName.phoneNumber)}
              />
            </div>
          </div>

          <label className={styles.label} htmlFor="message-input">
            Message
          </label>
          <InputField
            className={styles.input + ' ' + styles.messageInput}
            textarea
            id="message-input"
            name="message"
            helperTextClass={styles.helperText}
            {...getFieldProps(fieldsByName.message)}
          />
        </div>
        <div className={styles.submitButtonContainer}>
          {renderSubmitButton({
            labels: {
              ready: 'Get in touch',
              submitting: 'Sending...',
              submitted: 'Sent',
            },
            btnClasses: {
              common: styles.submitButton,
              ready: styles.formReady,
              submitting: styles.formSubmitting,
              submitted: styles.formSubmitted,
            },
            childrenBefore:
              (submitState === 'submitting' && (
                <FaSpinner
                  className={clsx(styles.buttonIcon, styles.buttonIconLoading)}
                ></FaSpinner>
              )) ||
              (submitState === 'submitted' && (
                <FaCheck className={clsx(styles.buttonIcon, styles.buttonIconSubmitted)}></FaCheck>
              )),
          })}
          {renderFormMessage({
            styles: {
              formMessage: styles.formMessage,
              formMessageSuccess: styles.formMessageSuccess,
              formMessageError: styles.formMessageError,
            },
          })}
        </div>
      </form>
    </div>
  );
};

export default Form;
